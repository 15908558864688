.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}

.nav-link {
    position: relative;
    padding-left: 20px;
    margin-right: 10px; 
}

section {
  overflow-x: hidden;
}

 body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


.nav-link::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border: 2px solid #bbb; 
    border-radius: 50%;
    transition: all 0.3s;
}

.nav-link.active::before {
    background-color: #000; 
    border-color: #000; 
    font-weight: bold;
}

.nav-link.active {
    color: #222222; 
    font-weight: bold; /* Optional: Makes the active nav-link text bold */
}


